import React from 'react';

import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { clientUrl } from '../../lib/utils';
import { Base } from '../../components/common';
import SEO from '../../components/seo';

import LoginAndRedirect from '../../components/login-with-secret/LoginAndRedirect';

import SectionArc from '../../components/common/SectionArc';

import CheckItem from '../../components/common/CheckItem';
import HeadTitle from '../../components/common/HeadTitle';
import OfferingItems from '../../components/common/OfferingItems';
import OfferingSection from '../../components/common/sections/OfferingSection';
import NewsletterSection from '../../components/first/sections/NewsletterSection';
import BooksSection from '../../components/first/sections/BooksSection';
import IntroSection from '../../components/first/sections/IntroSection';
import TestimonialSection from '../../components/first/sections/TestimonialSection';
import TopMessage from '../../components/first/TopMessage';
import Emoji from '../../components/common/Emoji';
import VideoRow from '../../components/first/VideoRow';

const OfferOfferings = () => (
  <OfferingItems hide14Days>
    <CheckItem
      text={
        <span style={{ fontWeight: 'bold' }}>
          Prova gratis i <span style={{ color: 'var(--color-green)' }}>30</span> dagar - inget
          betalkort krävs
        </span>
      }
    />
  </OfferingItems>
);

const FriendCampaignPage = () => {
  const { search } = useLocation();

  const { code, secret, target } = search ? queryString.parse(search) : {};
  if (secret) {
    return <LoginAndRedirect stay {...{ secret, target: target || 'kampanjer/laslov2020' }} />;
  }

  const days = 30;
  const ctaText = <>Prova gratis i 30&nbsp;dagar!</>;
  const ctaUrl = clientUrl(`/signup${code ? `?code=${code}` : ''}`);

  return (
    <Base ctaUrl={ctaUrl}>
      <SEO title="Vänkampanj" />

      <IntroSection
        {...{ ctaText, ctaUrl }}
        title={<HeadTitle>Din vän bjuder på {days}&nbsp;dagar gratis</HeadTitle>}
        topMessage={
          <TopMessage>
            Upptäck barnböckernas magiska värld med Boksnok - över 1000 barnböcker på ett och samma
            ställe <Emoji label="raketer" value="🚀🚀" />
          </TopMessage>
        }
        videoRow={
          <VideoRow>
            <OfferingItems darkMode emphasizeDays={days} />
          </VideoRow>
        }
      />
      <SectionArc topColor="purple" bottomColor="white" />
      <BooksSection bgColor="white" />
      <SectionArc topColor="white" bottomColor="green" flipped />
      <TestimonialSection bgColor="green" />
      <OfferingSection
        {...{ ctaText, ctaUrl }}
        buttonCentered
        buttonHalf={false}
        bgColor="blue-dark"
        offeringItems={<OfferOfferings />}
      />
      <SectionArc topColor="blue-dark" bottomColor="white" />
      <NewsletterSection bgColor="white" />
    </Base>
  );
};

export default FriendCampaignPage;
